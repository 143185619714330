import React from 'react'

//Quản trị
const HomeAdmin = React.lazy(() => import('../views/QuanTri/HomeAdmin/Index'))
//const ChucNang = React.lazy(() => import('../views/QuanTri/ChucNang/Index'));
const Website = React.lazy(() => import('../views/QuanTri/Website/Index'))
const NhomWebsite = React.lazy(() => import('../views/QuanTri/LoaiWebsite/Index'))
const DMPhanMem = React.lazy(() => import('../views/QuanTri/DMPhanMem/Index'))
const TaiKhoan = React.lazy(() => import('../views/QuanTri/TaiKhoan/Index'))
const Component = React.lazy(() => import('../views/Template/Page_Template'))
const Media = React.lazy(() => import('../views/Media/Index'))
const ThongTinNguoiDung = React.lazy(() => import('../views/ThongTinNguoiDung/Index'))

//CMS
// const CmsDashboard = React.lazy(() => import('../views/Dashboard/Cms/Index'));
const Post = React.lazy(() => import('../views/Cms/Post/Index'))
const DangTin = React.lazy(() => import('../views/Cms/DangTin/Page_Index'))
const Page = React.lazy(() => import('../views/Cms/Page/Page_Index'))
const CaiDat = React.lazy(() => import('../views/CaiDat/Page_Index'))
const ChinhSuaGiaoDien = React.lazy(() => import('../views/ChinhSuaGiaoDien/Page_Index'))
const Language = React.lazy(() => import('../views/Language/Index'))

//Quản lý bán hàng
// const DashboardBanHang = React.lazy(() => import('../views/Dashboard/BanHang/Index_Dashboard'));
const SanPham = React.lazy(() => import('../views/BanHang/SanPham/Index'))
const ThietLapGia = React.lazy(() => import('../views/BanHang/ThietLapGia/Index'))
// const TonKho = React.lazy(() => import("../views/BanHang/TonKho/Index"));
const DonHang = React.lazy(() => import('../views/BanHang/DonHang/Index'))

//Tương tác
const LienHe = React.lazy(() => import('../views/Crm/LienHe/Index'))
const DatLich = React.lazy(() => import('../views/Crm/DatLich/Page_DatLich'))

//CRM
const KhachHang = React.lazy(() => import('../views/Crm/KhachHang/Index'))
const CardVisit = React.lazy(() => import('../views/UngDung/CardVisit/Index'))
const HopDong = React.lazy(() => import('../views/Crm/HopDong/Index'))
const GiaHanDichVu = React.lazy(() => import('../views/Crm/DichVu/Index'))

//Kế toán
const Page_SoQuy = React.lazy(() => import('../views/QLThuChi/Page_Index'))

//Báo cáo
const KetQuaKinhDoanh = React.lazy(() => import('../views/BanHang/BaoCao/KetQuaKinhDoanh/Index'))

//Đầu tư
const MaGiaoDich = React.lazy(() => import('../views/Trade/MaGiaoDich/Index'))
const HieuQuaDauTu = React.lazy(() => import('../views/Trade/BaoCao/HieuQuaDauTu/Index'))
const DanhMucDauTu = React.lazy(() => import('../views/Trade/BaoCao/DanhMucDauTu/Index'))

//Thiết kế web
const ThietKeWeb = React.lazy(() => import('../views/ThietKeWeb/Page_Index'))

//Build PC
const BuildPC = React.lazy(() => import('../views/UngDung/BuildPC/Index'))

// const HeyThanThien = React.lazy(() => import('../views/UngDung/NhanDienGiongNoi/HeyThanThien'))

//Mining
const Mining_Dashboard = React.lazy(() => import('../views/UngDung/Mining/Dashboard/Index'))
const DMMayDao = React.lazy(() => import('../views/UngDung/Mining/DMMayDao/Index'))

//Mentoring
const Mentoring_Contacts = React.lazy(() => import('../views/Mentoring/Contacts/Index'))
const Mentoring_Matching = React.lazy(() => import('../views/Mentoring/Matching/Index'))
const Mentoring_Profile = React.lazy(() => import('../views/Mentoring/Profile/Index'))
const Mentoring_Sessions = React.lazy(() => import('../views/Mentoring/Sessions/Index'))

//add_marker_1

const chucnang = [
  //Quản trị
  { path: '/home-admin', exact: true, name: 'admin', element: HomeAdmin },
  //{ path: '/chuc-nang', exact: true, name: 'chuc-nang', element: ChucNang },
  { path: '/website', exact: true, name: 'website', element: Website },
  { path: '/nhom-website', exact: true, name: 'nhom-website', element: NhomWebsite },
  { path: '/dm-phan-mem', exact: true, name: 'dm-phan-mem', element: DMPhanMem },
  { path: '/tai-khoan', exact: true, name: 'tai-khoan', element: TaiKhoan },
  { path: '/component', exact: true, name: 'component', element: Component },
  { path: '/media', exact: true, name: 'Media', element: Media },
  { path: '/thong-tin-tai-khoan', exact: true, name: 'Profile', element: ThongTinNguoiDung },

  //CMS
  // { path: '/cms-dashboard', exact: true, name: 'Dashboard', element: CmsDashboard },
  {
    path: '/giao-dien',
    exact: true,
    name: 'Chỉnh sửa giao diện',
    element: ChinhSuaGiaoDien,
    odata: { url: '' },
  },
  { path: '/bai-viet', exact: true, name: 'BÀI VIẾT', element: Post, odata: { url: '' } },
  { path: '/dang-tin', exact: true, name: 'DANG TIN', element: DangTin, odata: { url: '' } },
  { path: '/menu-danh-muc', exact: true, name: 'MENU', element: Page, odata: { url: '' } },
  { path: '/cai-dat', exact: true, name: 'CÀI ĐẶT', element: CaiDat, odata: { url: '' } },
  { path: '/language', exact: true, name: 'language', element: Language, odata: { url: '' } },

  //Quản lý bán hàng
  // { path: '/tong-quan', exact: true, name: 'Dashboard', element: DashboardBanHang },
  { path: '/san-pham', exact: true, name: 'SẢN PHẨM', element: SanPham, odata: { url: '' } },
  {
    path: '/thiet-lap-gia',
    exact: true,
    name: 'THIẾT LẬP GIÁ',
    element: ThietLapGia,
    odata: { url: '' },
  },
  // { path: "/ton-kho", exact: true, name: "TỒN KHO", element: TonKho, odata: { url: "" } },
  { path: '/don-hang', exact: true, name: 'ĐƠN HÀNG', element: DonHang, odata: { url: '' } },

  //Tương tác
  { path: '/lien-he', exact: true, name: 'LIÊN HỆ', element: LienHe, odata: { url: '' } },
  { path: '/dat-lich', exact: true, name: 'ĐẶT LỊCH', element: DatLich, odata: { url: '' } },

  //Crm
  {
    path: '/khach-hang',
    exact: true,
    name: 'KHÁCH HÀNG',
    element: KhachHang,
    odata: { url: '' },
  },
  {
    path: '/card-visit-4.0',
    exact: true,
    name: 'Card visit',
    element: CardVisit,
    odata: { url: '' },
  },
  { path: '/hop-dong', exact: true, name: 'HỢP ĐỒNG', element: HopDong, odata: { url: '' } },
  {
    path: '/gia-han-dich-vu',
    exact: true,
    name: 'GiaHanDichVu',
    element: GiaHanDichVu,
    odata: { url: '' },
  },

  //Kế toán
  {
    path: '/so-quy-tien-mat',
    exact: true,
    name: 'SỔ QUỸ TIỀN MẶT',
    element: Page_SoQuy,
    odata: { url: '' },
  },

  //Báo cáo
  {
    path: '/bao-cao-tai-chinh',
    exact: true,
    name: 'BÁO CÁO TÀI CHÍNH',
    element: KetQuaKinhDoanh,
    odata: { url: '' },
  },

  //Đầu tư
  {
    path: '/dau-tu/ma-giao-dich',
    exact: true,
    name: 'Mã giao dịch',
    element: MaGiaoDich,
    odata: { url: '' },
  },
  {
    path: '/trade/bao-cao-hieu-qua-dau-tu',
    exact: true,
    name: 'Hiệu quả đầu tư',
    element: HieuQuaDauTu,
    odata: { url: '' },
  },
  {
    path: '/dau-tu/danh-muc-dau-tu',
    exact: true,
    name: 'Danh mục đầu tư',
    element: DanhMucDauTu,
    odata: { url: '' },
  },

  //Thiết kế web
  {
    path: '/thiet-ke-giao-dien',
    exact: true,
    name: 'Thiết kế giao diện',
    element: ThietKeWeb,
    odata: { url: '' },
  },

  //Build PC
  { path: '/buildpc', exact: true, name: 'Build PC', element: BuildPC, odata: { url: '' } },

  // { path: '/hey', exact: true, name: 'Hey Thân Thiện', element: HeyThanThien, free: true },

  //Mining
  {
    path: '/mining-dashboard',
    exact: true,
    name: 'dashboard',
    element: Mining_Dashboard,
    odata: { url: '' },
  },
  { path: '/worker', exact: true, name: 'worker', element: DMMayDao, odata: { url: '' } },

  //Mentoring
  {
    path: '/mentoring/contacts',
    exact: true,
    name: 'mentoring/contacts',
    element: Mentoring_Contacts,
    odata: { url: '' },
  },
  {
    path: '/mentoring/matching',
    exact: true,
    name: 'mentoring/matching',
    element: Mentoring_Matching,
    odata: { url: '' },
  },
  {
    path: '/mentoring/profile',
    exact: true,
    name: 'mentoring/profile',
    element: Mentoring_Profile,
    odata: { url: '' },
  },
  {
    path: '/mentoring/sessions',
    exact: true,
    name: 'mentoring/sessions',
    element: Mentoring_Sessions,
    odata: { url: '' },
  },
  //add_marker_2
]

export default chucnang
