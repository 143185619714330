import Cookies from 'js-cookie'
//config host
//export const _API_URL = 'http://localhost:57874'
export const _API_URL = 'https://api.thanthienweb.com'
//port fake
// export const API_URL
export const API_URL = _API_URL + '/odata'
// export const API_URL_MULTI
export const API_URL_MULTI = _API_URL + '/AddMulti'
// *************************************************************************
// Biến token client
export const ID_TOKEN_CLIENT = '_thanthien'
export const ID_TOKEN_INFO = 'info'
export const ID_TOKEN_MENU = 'menu'
export const ID_TOKEN_PERMISSION = 'permission'
export const SETTINGS = 'settings'
// GET token localstorage client
export const TOKEN_CLIENT =
  Cookies.get(ID_TOKEN_CLIENT) === undefined ? '' : Cookies.get(ID_TOKEN_CLIENT)
// GET token info client
export const TOKEN_CLIENT_INFO =
  localStorage.getItem(ID_TOKEN_INFO) === 'undefined'
    ? null
    : JSON.parse(localStorage.getItem(ID_TOKEN_INFO))
export const PERMISSION =
  localStorage.getItem(ID_TOKEN_PERMISSION) === 'undefined'
    ? null
    : JSON.parse(localStorage.getItem(ID_TOKEN_PERMISSION))

// GET cookie DiemKinhDoanh va Kho current
export const CURRENT_DKT_KHO = 'CurrentDKDKho'
export const TOKEN_DKT_KHO = JSON.parse(localStorage.getItem(CURRENT_DKT_KHO) || null)
