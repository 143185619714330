import { format } from 'date-fns'
import moment from 'moment'
import { TOKEN_CLIENT_INFO } from './types'

// Thẩm định Email
export function VALIDATE_EMAIL(email) {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
}
// Loading
export function OPEN_LOADING_DATA_SERVER_API(msg, s) {
  // modal.style.display = "none";
  document
    .getElementById('Modal-show-loading-data-api-call')
    .classList.add('Modal-show-loading-data-show-modal')
}
export function CLOSE_LOADING_DATA_SERVER_API(msg, s) {
  // modal.style.display = "none";
  document
    .getElementById('Modal-show-loading-data-api-call')
    .classList.remove('Modal-show-loading-data-show-modal')
}
// Format Date
export function formatDate(value) {
  return value ? format(new Date(value), 'dd/MM/yyyy') : ''
}
export function formatTime(value) {
  return value ? format(new Date(value), 'HH:mm:ss') : ''
}
export function formatDatetime(value) {
  return value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : ''
}
export function formatISODate(value) {
  return value ? format(new Date(value), "yyyy-MM-dd'T'HH:mm:ss") : null
}
export function getSizeStr(size) {
  let value = size
  if (value < 1000) return value + ' bytes'
  value = value / 1000
  if (value < 1000) return value.toFixed(2) + ' KB'
  value = value / 1000
  if (value < 1000) return value.toFixed(2) + ' MB'
  value = value / 1000
  return value.toFixed(2) + ' GB'
}
// Format Currency
export function formatCurrency(value) {
  if (TOKEN_CLIENT_INFO && TOKEN_CLIENT_INFO.Language == 'EN') {
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
      value !== undefined ? value : 0,
    )
  } else {
    return new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(
      value !== undefined ? value : 0,
    )
  }

  //return new Intl.NumberFormat('vi').format(value !== undefined ? value : 0)
}
export function formatCurrencyWithSymbol(value, symbol) {
  if (symbol === 'USD') {
    return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(
      value !== undefined ? value : 0,
    )
  } else {
    return new Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(
      value !== undefined ? value : 0,
    )
  }
}
export function formatNumber(value) {
  if (TOKEN_CLIENT_INFO && TOKEN_CLIENT_INFO.Language == 'EN') {
    return new Intl.NumberFormat('en').format(value !== undefined ? value : 0)
  } else {
    return new Intl.NumberFormat('vi').format(value !== undefined ? value : 0)
  }
}
export function date_diff_indays(date1, date2) {
  let dt1 = new Date(date1)
  let dt2 = new Date(date2)
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24),
  )
}

export function getDuration(date1, date2) {
  var x = new moment(date2)
  var y = new moment(date1)
  return moment.duration(x.diff(y))
}

export function getThisMonth() {
  let today = new Date()
  return today.getMonth()
}

export function getThisYear() {
  let today = new Date()
  return today.getFullYear()
}

export function getDateByUnix(t) {
  return format(new Date(t), 'dd/MM/yyyy')
}

export function sumArray(arr, field) {
  if (arr) return arr.map((item) => item[field]).reduce((prev, curr) => prev + curr, 0)
  else return 0
}

export function averageArray(arr, field) {
  if (arr) {
    let sum = 0
    let n = 0
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][field] > 0) {
        sum += arr[i][field]
        n++
      }
    }

    return sum / n
  } else {
    return 0
  }
}

export const DomainName = () => {
  let domain = new URL(window.location.href)
  return domain.hostname === 'localhost' ? 'ementorhub.thanthienweb.com' : domain.hostname
}
