import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import _routes from './routes'
import { callApiPublic, callApiNotLoading, callApi2 } from '../service/api'
import { CHECK_ERROR } from '../childcomp/alert/Alert'
import {
  TOKEN_CLIENT,
  PERMISSION,
  ID_TOKEN_PERMISSION,
  SETTINGS,
  ID_TOKEN_CLIENT,
  ID_TOKEN_INFO,
  ID_TOKEN_MENU,
} from '../actions/types'
import { OPEN_LOADING_DATA_SERVER_API, CLOSE_LOADING_DATA_SERVER_API } from '../actions/util'

const checkCondition = (route, permission) => route.free || route.path === permission.url
const checkHasPermission = (route, permissions) =>
  permissions.some((permission) => checkCondition(route, permission))

//get setting by name
export function getSettingByName(name) {
  if (localStorage.getItem(SETTINGS) !== null) {
    try {
      const list = JSON.parse(localStorage.getItem(SETTINGS))
      return list.find((e) => e.SETTING_KEY === name)
    } catch (err) {
      console.log(err)
    }
  }
}

//get setting by name
export function getSettingValueByName(name) {
  const setting = getSettingByName(name)
  if (setting) {
    if (setting.SETTING_TYPE === 'boolean') {
      return setting.SETTING_VALUE === 'true'
    }
    if (setting.SETTING_TYPE === 'numeric') {
      return Number(setting.SETTING_VALUE)
    }
    return setting.SETTING_VALUE
  }
  return null
}

//get settings
export function getListSetting() {
  if (localStorage.getItem(SETTINGS) === null) {
    callApiNotLoading('Settings?$select=SETTING_KEY,SETTING_VALUE,SETTING_TYPE')
      .then((res) => res.value && localStorage.setItem(SETTINGS, JSON.stringify(res.value)))
      .catch((err) => console.log(err))
  }
}

//check permission
export function getPermissions() {
  //console.log(PERMISSION);
  if (PERMISSION !== null) {
    return PERMISSION
  }

  return []
}

//check permission
export function checkPermission(action) {
  if (!action) return false
  var chuc_nang = PERMISSION.filter((item) => item.url === window.location.pathname)
  //console.log(chuc_nang);
  if (chuc_nang && chuc_nang[0] && chuc_nang[0][action]) {
    return chuc_nang[0][action]
  } else {
    return false
  }
}

//check routes permission
export function checkPermissionsRoutes() {
  let routes = []
  let permissions = getPermissions()
  // Lọc những route có trong danh sách quyền
  routes = _routes.filter((route) => checkHasPermission(route, permissions))
  return [...routes]
}

/*******************************************************/
export function getMenu() {
  if (localStorage.getItem('menu') !== 'undefined') {
    return JSON.parse(localStorage.getItem('menu'))
  }
  return []
}

export function getMenuThietLap() {
  if (localStorage.getItem('menu_thietlap') !== 'undefined') {
    return JSON.parse(localStorage.getItem('menu_thietlap'))
  }
  return []
}

export function getListApp() {
  if (localStorage.getItem('list_app') !== 'undefined') {
    return JSON.parse(localStorage.getItem('list_app'))
  }
  return []
}

function clearTokens() {
  Cookies.remove(ID_TOKEN_CLIENT)
  localStorage.removeItem(ID_TOKEN_INFO)
  localStorage.removeItem(ID_TOKEN_MENU)
  localStorage.removeItem(ID_TOKEN_PERMISSION)
  localStorage.removeItem(SETTINGS)
  localStorage.removeItem('selectedApp')
  localStorage.removeItem('list_app')
}

//check logins
export function checkLogin() {
  if (TOKEN_CLIENT === undefined || TOKEN_CLIENT === '') {
    clearTokens()
    return false
  }
  try {
    const { iss, aud } = jwt_decode(TOKEN_CLIENT)
    if (iss === 'thanthien' && aud === 'thanthien') {
      if (localStorage.getItem(ID_TOKEN_INFO) != null) {
        return true
      }
    }
  } catch {
    clearTokens()
  }
  return false
}

export async function loginAccount(username, password) {
  let response = await callApiPublic(
    `Login/Authenticate`,
    'POST',
    JSON.stringify({
      UserName: username,
      Password: password,
    }),
  )
    .then((res) => res.json())
    .catch((err) => CHECK_ERROR(err))

  try {
    if (response.Message !== undefined) {
      CHECK_ERROR(response.Message)
      return false
    }

    if (response.menu && response.danh_sach_chuc_nang) {
      Cookies.set(ID_TOKEN_CLIENT, response.Token, { path: '/' })
      localStorage.setItem(ID_TOKEN_INFO, JSON.stringify(response.TaiKhoan))
      localStorage.setItem('selectedApp', JSON.stringify(response.SelectedApp))
      localStorage.setItem('menu', JSON.stringify(response.menu))
      localStorage.setItem('menu_thietlap', JSON.stringify(response.menu_thietlap))
      localStorage.setItem('list_app', JSON.stringify(response.list_app))
      localStorage.setItem(ID_TOKEN_PERMISSION, JSON.stringify(response.danh_sach_chuc_nang))

      window.location.replace(
        response.TaiKhoan.TrangChu ? `/#${response.TaiKhoan.TrangChu}` : '/111',
      )
      window.location.reload(true)
    }
  } catch (err) {
    CHECK_ERROR('Không thể đăng nhập !')
    return false
  }
}

export function logoutAccount() {
  OPEN_LOADING_DATA_SERVER_API()
  setTimeout(function () {
    callApi2('Login/LogOff', 'POST', null, false)
      .then(() => {
        clearTokens()
        window.location.href = '/#/login'
        window.location.reload(true)
      })
      .catch((err) => {
        clearTokens()
        window.location.href = '/#/login'
        window.location.reload(true)
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  }, 500)
}
