import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import Spinners from './childcomp/spinners/Spinners'
import styled from 'styled-components'

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.5;
  padding: 1rem 1.5rem;
  width: auto;
  border-radius: 0.5rem;
`

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//     <div id="Modal-show-loading-data-api-call" className="Modal-show-loading-data-modal">
//       <ModalContent>
//         <Spinners />
//       </ModalContent>
//     </div>
//   </Provider>,
//   document.getElementById('root'),
// )

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
    <div id="Modal-show-loading-data-api-call" className="Modal-show-loading-data-modal">
      <ModalContent>
        <Spinners />
      </ModalContent>
    </div>
  </Provider>,
)
reportWebVitals()
