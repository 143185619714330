import * as Config from '../../actions/types'
import { OPEN_LOADING_DATA_SERVER_API, CLOSE_LOADING_DATA_SERVER_API } from '../../actions/util'
import { CHECK_ERROR } from '../../childcomp/alert/Alert'
import { logoutAccount } from '../../routes/permission'
export { _API_URL, API_URL, API_URL_MULTI } from '../../actions/types'

const headers = new Headers()
headers.append('Accept', 'application/json')
headers.append('Content-Type', 'application/json')
headers.append('Authorization', 'Bearer ' + Config.TOKEN_CLIENT)

/**
 * fetch trả về data kèm status
 * Thành công 200 - 300
 */
export function gdtFetch(url, options) {
  return fetch(url, options)
    .then((res) =>
      res.json().then((data) => ({
        status: res.status,
        data,
      })),
    )
    .then((res) => {
      if (res.status >= 200 && res.status < 300) {
        return Promise.resolve(res)
      } else {
        return Promise.reject(res)
      }
    })
}

//call API
export async function callApi(endpoint, method, body) {
  let url = `${Config.API_URL}/${endpoint}`
  const result = await fetch(url, {
    method: method,
    mode: 'cors',
    headers,
    body: body,
  })
  if (!result.ok) throw (await result.json())['error']
  return await result.json()
}

export async function callApiMulti(endpoint, method, body) {
  let url = `${Config.API_URL_MULTI}/${endpoint}`
  const result = await fetch(url, {
    method: method,
    mode: 'cors',
    headers,
    body: body,
  })

  return result
}

export async function _callApi(endpoint, method, body) {
  let url = `${Config._API_URL}/${endpoint}`
  const result = await fetch(url, {
    method: method,
    mode: 'cors',
    headers,
    body: body,
  })
  if (!result.ok) throw await result.text()
  return result
}

//call API
export function callApi2(endpoint, method = 'GET', body, odata = true, enableSpinner = true) {
  return new Promise((resolve, reject) => {
    if (enableSpinner) OPEN_LOADING_DATA_SERVER_API()
    var api = odata ? Config.API_URL : Config._API_URL
    fetch(`${api}/${endpoint}`, {
      method: method,
      headers,
      mode: 'cors',
      body: body,
      credentials: 'same-origin',
    })
      .then(async (res) => {
        if (
          res.status === 200 &&
          (method === 'GET' || method === 'POST' || method === 'PUT' || method === 'DELETE')
        ) {
          resolve(res.json())
        } else if (res.status === 201 && (method === 'POST' || method === 'PUT')) {
          resolve(res.json())
        } else if (
          res.status === 204 &&
          (method === 'PUT' || method === 'PATCH' || method === 'DELETE')
        ) {
          resolve(res)
        } else if (res.status === 401) {
          logoutAccount()
        } else {
          reject(await res.json())
        }
      })
      .catch((err) => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

//call API
export function callApiNotLoading(endpoint, method = 'GET', body, odata = true) {
  return new Promise((resolve, reject) => {
    var api = odata ? Config.API_URL : Config._API_URL

    fetch(`${api}/${endpoint}`, { method: method, headers, mode: 'cors', body: body })
      .then(async (res) => {
        if (res.status === 200 && method === 'GET') {
          resolve(res.json())
        } else if (res.status === 201 && (method === 'POST' || method === 'PUT')) {
          resolve(res.json())
        } else if (
          res.status === 204 &&
          (method === 'PUT' || method === 'PATCH' || method === 'DELETE')
        ) {
          resolve(res)
        } else {
          reject(await res.json())
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function callApiFormData(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API()
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json',
        //'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + Config.TOKEN_CLIENT,
      },
      body: body, // body data type must match "Content-Type" header
    })
      .then((res) => resolve(res.json()))
      .catch((err) => {
        CHECK_ERROR('Không thể lấy dữ liệu !')
        reject(err)
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApiDownload(endpoint, filename, method = 'GET', body, download) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API()
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + Config.TOKEN_CLIENT,
      },
      body: body,
    })
      .then((res) => {
        if (!res.ok) reject('Lỗi tải file: ' + res.status)
        return res.blob()
      })
      .then((blob) => {
        let url = URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', filename || 'download.pdf')
        link.click()
        link.remove()
        URL.revokeObjectURL(url)
        resolve(blob)
      })
      .catch((err) => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApiPublic(endpoint, method = 'GET', body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API()
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      body: body,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApi2FakeVGCA(endpoint) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API()
    fetch(endpoint, { method: 'GET', headers, mode: 'cors', body: null })
      .then((res) => resolve(res))
      .catch((err) => {
        CHECK_ERROR('Không thể lấy dữ liệu !')
        reject(err)
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApiFormDataFakeVGCA(endpoint, formData) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API()
    fetch(`${endpoint}`, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    })
      .then((res) => resolve(res.json()))
      .catch((err) => {
        CHECK_ERROR('Không thể giả lập !')
        reject(err)
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
  //
}
