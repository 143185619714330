import Swal from 'sweetalert2'

export function CHECK_SUCCESS(mes, titles) {
  return Swal.fire({ type: 'success', title: titles, text: mes })
}

export function CHECK_WARNING(mes, titles) {
  return Swal.fire({ type: 'warning', title: titles, text: mes })
}

export function CHECK_ERROR(mes, titles) {
  return Swal.fire({ type: 'error', title: titles, text: mes })
}

export function CHECK_CONFIRM(mes, titles) {
  return new Promise((resolve, reject) =>
    Swal.fire({
      title: titles,
      text: mes,
      showCancelButton: true,
      confirmButtonText: 'Chấp nhận',
      cancelButtonText: 'Không',
    })
      .then((result) => result.value && resolve(result))
      .catch((err) => reject(err)),
  )
}

export function CHECK_CONFIRM_INPUT(mes, titles, placeholder, isValidator) {
  return new Promise((resolve, reject) =>
    Swal.fire({
      title: titles,
      text: mes,
      input: 'textarea',
      inputPlaceholder: placeholder,
      inputAttributes: { 'aria-label': placeholder },
      inputValidator: (value) => {
        if (isValidator) {
          if (!value) {
            return placeholder ? placeholder : 'Vui lòng nhập thông tin'
          }
        }
      },
      showCancelButton: true,
      confirmButtonText: 'Chấp nhận',
      cancelButtonText: 'Không',
    })
      .then((result) => result.value && resolve(result.value))
      .catch((err) => reject(err)),
  )
}
